<groupui-modal
	*transloco="let t"
	m="4"
	l="8"
	xl="6"
	xxl="4"
	[displayed]="fieldSettings.isOpen"
	[closeButton]="fieldSettings.closeAble === undefined ? true : fieldSettings.closeAble"
	[disableBackdropClose]="true"
	[disableEscapeClose]="true"
	(groupuiModalClose)="closeModal(fieldSettings.onClose)">
	<div class="display-flex flex-direction-column gap-groupui-spacing-7">
		<groupui-headline heading="h3">{{ t($any(fieldSettings.title)) }}</groupui-headline>

		<!-- modal content -->
		<div>
			<groupui-text [innerHTML]="t($any(fieldSettings.contentText)) | sanitizeHtml"></groupui-text>
			<div *ngIf="fieldSettings.copyContent">
				<div class="display-flex gap-groupui-spacing-3">
					<groupui-input [readonly]="true" class="max-width-300px" [value]="fieldSettings.copyContent.copyValue"></groupui-input>
					<groupui-button
						icon-only="true"
						variant="tertiary"
						size="m"
						icon="copy-24"
						*btrCopyValue="fieldSettings.copyContent.copyValue"></groupui-button>
				</div>
			</div>
		</div>

		<!-- modal parent copy content -->

		<div *ngIf="fieldSettings.parentCopyContent">
			<groupui-text>{{ t($any(fieldSettings.parentCopyContent.copyText)) }}</groupui-text>
			<div class="display-flex gap-groupui-spacing-3">
				<groupui-input [readonly]="true" class="max-width-300px" [value]="fieldSettings.parentCopyContent.copyValue"></groupui-input>
				<groupui-button
					icon-only="true"
					variant="tertiary"
					size="m"
					icon="copy-24"
					*btrCopyValue="fieldSettings.parentCopyContent.copyValue"></groupui-button>
			</div>
		</div>
		<ng-content></ng-content>

		<!-- modal optional content -->
		<div class="notification-optional-content" *ngIf="fieldSettings.optionalMessage">
			<groupui-accordion iconPosition="left">
				<span slot="headline">
					<groupui-headline heading="h6" weight="light">Backend Message</groupui-headline>
				</span>
				{{ fieldSettings.optionalMessage }}
			</groupui-accordion>
		</div>

		<!-- modal button footer -->
		<div class="display-flex gap-groupui-spacing-3 justify-content-flex-end" *ngIf="fieldSettings?.primaryButton || fieldSettings?.secondaryButton">
			<groupui-button
				class="notification-secondary-button"
				*ngIf="fieldSettings?.secondaryButton"
				variant="secondary"
				(click)="closeModal(fieldSettings.secondaryButton?.function); secondaryClick.emit(fieldSettings.payload)"
				data-test="btn-secondary"
				[icon]="fieldSettings.secondaryButton?.icon">
				{{ t($any(fieldSettings.secondaryButton?.label)) }}
			</groupui-button>
			<groupui-button
				class="notification-primary-button"
				*ngIf="fieldSettings?.primaryButton && !fieldSettings.dangerButton"
				variant="primary"
				(click)="closeModal(fieldSettings.primaryButton?.function); primaryClick.emit(fieldSettings.payload)"
				data-test="btn-primary"
				[icon]="fieldSettings.primaryButton?.icon">
				{{ t($any(fieldSettings.primaryButton?.label)) }}
			</groupui-button>
			<groupui-button
				class="notification-primary-button"
				*ngIf="fieldSettings?.primaryButton && fieldSettings.dangerButton"
				variant="primary"
				severity="danger"
				(click)="closeModal(fieldSettings.primaryButton?.function); primaryClick.emit(fieldSettings.payload)"
				data-test="btn-primary"
				[icon]="fieldSettings.primaryButton?.icon">
				{{ t($any(fieldSettings.primaryButton?.label)) }}
			</groupui-button>
		</div>
	</div>
</groupui-modal>
