import { NavItem } from '../environment.interface';
import { PagePaths } from '@core/enum/page-paths.enum';
import { Roles } from '@core/enum/roles.enum';

export enum NAVIGATION_ITEM_ID {
	HOME = 'HOME',

	CONTRACT_MANAGEMENT = 'CONTRACT_MANAGEMENT',
	CONTRACT_MANAGEMENT_ALL_CONTRACTS_BY_PARTNER_ID = 'CONTRACT_MANAGEMENT_ALL_CONTRACTS_BY_PARTNER_ID',
	CONTRACT_MANAGEMENT_NEW = 'CONTRACT_MANAGEMENT_NEW',
	CONTRACT_MANAGEMENT_DETAIL = 'CONTRACT_MANAGEMENT_DETAIL',
	CONTRACT_MANAGEMENT_COMPONENT_READ_PERMISSION = 'CONTRACT_MANAGEMENT_COMPONENT_READ_PERMISSION',
	CONTRACT_MANAGEMENT_COMPONENT_WRITE_PERMISSION = 'CONTRACT_MANAGEMENT_COMPONENT_WRITE_PERMISSION',

	COMPONENT_MANAGEMENT = 'COMPONENT_MANAGEMENT',
	COMPONENT_MANAGEMENT_ALL_COMPONENTS_BY_CONTRACT_ID = 'COMPONENT_MANAGEMENT_ALL_COMPONENTS_BY_CONTRACT_ID',
	COMPONENT_MANAGEMENT_NEW = 'COMPONENT_MANAGEMENT_NEW',
	COMPONENT_MANAGEMENT_UPDATE = 'COMPONENT_MANAGEMENT_UPDATE',
	COMPONENT_MANAGEMENT_DETAIL = 'COMPONENT_MANAGEMENT_DETAIL',

	PART_TYPE_MANAGEMENT = 'PART_TYPE_MANAGEMENT',
	PART_TYPE_MANAGEMENT_ALL_PART_TYPES = 'PART_TYPE_MANAGEMENT_ALL_PART_TYPES',
	PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER = 'PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER',
	PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER_GROUP = 'PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER_GROUP',

	PARTNER_MANAGEMENT = 'PARTNER_MANAGEMENT',
	PARTNER_MANAGEMENT_ALL_PARTNERS = 'PARTNER_MANAGEMENT_ALL_PARTNERS',
	PARTNER_MANAGEMENT_MANAGE_ASSIGNED_PARTNER_GROUPS = 'PARTNER_MANAGEMENT_MANAGE_ASSIGNED_PARTNER_GROUPS',
	PARTNER_MANAGEMENT_PERMITTED_PARTNER_RELATION = 'PARTNER_MANAGEMENT_PERMITTED_PARTNER_RELATION',

	PARTNER_GROUP_MANAGEMENT = 'PARTNER_GROUP_MANAGEMENT',
	PARTNER_GROUP_MANAGEMENT_ALL_PARTNER_GROUPS = 'PARTNER_GROUP_MANAGEMENT_ALL_PARTNER_GROUPS',

	PRODUCT_PASS = 'PRODUCT_PASS',
	PRODUCT_PASS_ALL_PRODUCT_PASSES = 'PRODUCT_PASS_ALL_PRODUCT_PASSES',
	PRODUCT_PASS_ALL_PRODUCT_PASS_TEMPLATES = 'PRODUCT_PASS_ALL_PRODUCT_PASS_TEMPLATES',
	PRODUCT_PASS_NEW = 'PRODUCT_PASS_NEW',
	PRODUCT_PASS_TEMPLATE_NEW = 'PRODUCT_PASS_TEMPLATE_NEW',
	PRODUCT_PASS_DETAIL = 'PRODUCT_PASS_DETAIL',
	PRODUCT_PASS_TEMPLATE_DETAIL = 'PRODUCT_PASS_TEMPLATE_DETAIL',
}

export const NAVIGATION_ITEM_MAP: Record<NAVIGATION_ITEM_ID, NavItem> = {
	[NAVIGATION_ITEM_ID.HOME]: {
		id: NAVIGATION_ITEM_ID.HOME,
		url: PagePaths.HOME,
	},
	// Contract
	[NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT]: {
		id: NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT,
		icon: 'file-text-32',
		canNavigateToRoles: [Roles.ADMIN_ROLE, Roles.CONTRACT_MANAGER_ROLE],
	},
	[NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_ALL_CONTRACTS_BY_PARTNER_ID]: {
		id: NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_ALL_CONTRACTS_BY_PARTNER_ID,
		icon: 'list-view-24',
		url: PagePaths.CONTRACT_MANAGEMENT_ALL_CONTRACTS_BY_PARTNER_ID,
		canNavigateToRoles: [Roles.CONTRACT_MANAGER_ROLE],
	},
	[NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_NEW]: {
		id: NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_NEW,
		icon: 'plus-24',
		url: PagePaths.CONTRACT_MANAGEMENT_NEW,
		canNavigateToRoles: [Roles.CONTRACT_MANAGER_ROLE],
	},
	[NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_DETAIL]: {
		id: NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_DETAIL,
		url: PagePaths.CONTRACT_MANAGEMENT_DETAIL,
		canNavigateToRoles: [Roles.CONTRACT_MANAGER_ROLE],
	},
	[NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_COMPONENT_READ_PERMISSION]: {
		id: NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_COMPONENT_READ_PERMISSION,
		icon: 'pencil-24',
		url: PagePaths.CONTRACT_MANAGEMENT_COMPONENT_READ_PERMISSION,
		canNavigateToRoles: [Roles.ADMIN_ROLE],
	},
	[NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_COMPONENT_WRITE_PERMISSION]: {
		id: NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_COMPONENT_WRITE_PERMISSION,
		icon: 'pencil-24',
		url: PagePaths.CONTRACT_MANAGEMENT_COMPONENT_WRITE_PERMISSION,
		canNavigateToRoles: [Roles.ADMIN_ROLE],
	},
	// Component
	[NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT]: {
		id: NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT,
		icon: 'gears-32',
		canNavigateToRoles: [Roles.DATA_MANAGER_ROLE],
	},
	[NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT_ALL_COMPONENTS_BY_CONTRACT_ID]: {
		id: NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT_ALL_COMPONENTS_BY_CONTRACT_ID,
		icon: 'list-view-24',
		url: PagePaths.COMPONENT_MANAGEMENT_ALL_COMPONENTS_BY_CONTRACT_ID,
		canNavigateToRoles: [Roles.DATA_MANAGER_ROLE],
	},
	[NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT_NEW]: {
		id: NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT_NEW,
		icon: 'plus-24',
		url: PagePaths.COMPONENT_MANAGEMENT_NEW,
		canNavigateToRoles: [Roles.DATA_MANAGER_ROLE],
	},
	[NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT_UPDATE]: {
		id: NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT_UPDATE,
		icon: 'pencil-24',
		url: PagePaths.COMPONENT_MANAGEMENT_UPDATE,
		canNavigateToRoles: [Roles.DATA_MANAGER_ROLE],
	},
	[NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT_DETAIL]: {
		id: NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT_DETAIL,
		url: PagePaths.COMPONENT_MANAGEMENT_DETAIL,
		canNavigateToRoles: [Roles.CONTRACT_MANAGER_ROLE],
	},
	// Part Type
	[NAVIGATION_ITEM_ID.PART_TYPE_MANAGEMENT]: {
		id: NAVIGATION_ITEM_ID.PART_TYPE_MANAGEMENT,
		icon: 'car-body-32',
		canNavigateToRoles: [Roles.ADMIN_ROLE],
	},
	[NAVIGATION_ITEM_ID.PART_TYPE_MANAGEMENT_ALL_PART_TYPES]: {
		id: NAVIGATION_ITEM_ID.PART_TYPE_MANAGEMENT_ALL_PART_TYPES,
		icon: 'list-view-24',
		url: PagePaths.PART_TYPE_MANAGEMENT_ALL_PART_TYPES,
		canNavigateToRoles: [Roles.ADMIN_ROLE],
	},
	[NAVIGATION_ITEM_ID.PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER]: {
		id: NAVIGATION_ITEM_ID.PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER,
		icon: 'pencil-24',
		url: PagePaths.PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER,
		canNavigateToRoles: [Roles.ADMIN_ROLE],
	},
	[NAVIGATION_ITEM_ID.PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER_GROUP]: {
		id: NAVIGATION_ITEM_ID.PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER_GROUP,
		icon: 'pencil-24',
		url: PagePaths.PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER_GROUP,
		canNavigateToRoles: [Roles.ADMIN_ROLE],
	},
	[NAVIGATION_ITEM_ID.PARTNER_MANAGEMENT]: {
		id: NAVIGATION_ITEM_ID.PARTNER_MANAGEMENT,
		icon: 'user-32',
		canNavigateToRoles: [Roles.ADMIN_ROLE],
	},
	[NAVIGATION_ITEM_ID.PARTNER_MANAGEMENT_ALL_PARTNERS]: {
		id: NAVIGATION_ITEM_ID.PARTNER_MANAGEMENT_ALL_PARTNERS,
		icon: 'list-view-24',
		url: PagePaths.PARTNER_MANAGEMENT_ALL_PARTNERS,
		canNavigateToRoles: [Roles.ADMIN_ROLE],
	},
	[NAVIGATION_ITEM_ID.PARTNER_MANAGEMENT_MANAGE_ASSIGNED_PARTNER_GROUPS]: {
		id: NAVIGATION_ITEM_ID.PARTNER_MANAGEMENT_MANAGE_ASSIGNED_PARTNER_GROUPS,
		icon: 'pencil-24',
		url: PagePaths.PARTNER_MANAGEMENT_MANAGE_ASSIGNED_PARTNER_GROUPS,
		canNavigateToRoles: [Roles.ADMIN_ROLE],
	},
	[NAVIGATION_ITEM_ID.PARTNER_MANAGEMENT_PERMITTED_PARTNER_RELATION]: {
		id: NAVIGATION_ITEM_ID.PARTNER_MANAGEMENT_PERMITTED_PARTNER_RELATION,
		icon: 'pencil-24',
		url: PagePaths.PARTNER_MANAGEMENT_PERMITTED_PARTNER_RELATION,
		canNavigateToRoles: [Roles.ADMIN_ROLE],
	},
	[NAVIGATION_ITEM_ID.PARTNER_GROUP_MANAGEMENT]: {
		id: NAVIGATION_ITEM_ID.PARTNER_GROUP_MANAGEMENT,
		icon: 'user-group-32',
		canNavigateToRoles: [Roles.ADMIN_ROLE],
	},
	[NAVIGATION_ITEM_ID.PARTNER_GROUP_MANAGEMENT_ALL_PARTNER_GROUPS]: {
		id: NAVIGATION_ITEM_ID.PARTNER_GROUP_MANAGEMENT_ALL_PARTNER_GROUPS,
		icon: 'list-view-24',
		url: PagePaths.PARTNER_GROUP_MANAGEMENT_ALL_PARTNER_GROUPS,
		canNavigateToRoles: [Roles.ADMIN_ROLE],
	},
	//Product Pass
	[NAVIGATION_ITEM_ID.PRODUCT_PASS]: {
		id: NAVIGATION_ITEM_ID.PRODUCT_PASS,
		icon: 'passport-32',
		canNavigateToRoles: [Roles.ADMIN_ROLE, Roles.PRODUCT_PASS_MANAGER_ROLE, Roles.PRODUCT_PASS_APPROVER_ROLE],
	},
	[NAVIGATION_ITEM_ID.PRODUCT_PASS_ALL_PRODUCT_PASSES]: {
		id: NAVIGATION_ITEM_ID.PRODUCT_PASS_ALL_PRODUCT_PASSES,
		icon: 'list-view-24',
		url: PagePaths.PRODUCT_PASS_ALL_PRODUCT_PASSES,
		canNavigateToRoles: [Roles.PRODUCT_PASS_MANAGER_ROLE, Roles.PRODUCT_PASS_APPROVER_ROLE],
	},
	[NAVIGATION_ITEM_ID.PRODUCT_PASS_ALL_PRODUCT_PASS_TEMPLATES]: {
		id: NAVIGATION_ITEM_ID.PRODUCT_PASS_ALL_PRODUCT_PASS_TEMPLATES,
		icon: 'list-view-24',
		url: PagePaths.PRODUCT_PASS_ALL_PRODUCT_PASS_TEMPLATES,
		canNavigateToRoles: [Roles.ADMIN_ROLE, Roles.PRODUCT_PASS_MANAGER_ROLE],
	},
	[NAVIGATION_ITEM_ID.PRODUCT_PASS_NEW]: {
		id: NAVIGATION_ITEM_ID.PRODUCT_PASS_NEW,
		url: PagePaths.PRODUCT_PASS_NEW,
		canNavigateToRoles: [Roles.PRODUCT_PASS_MANAGER_ROLE],
	},
	[NAVIGATION_ITEM_ID.PRODUCT_PASS_TEMPLATE_NEW]: {
		id: NAVIGATION_ITEM_ID.PRODUCT_PASS_TEMPLATE_NEW,
		url: PagePaths.PRODUCT_PASS_TEMPLATE_NEW,
		canNavigateToRoles: [Roles.ADMIN_ROLE],
	},
	[NAVIGATION_ITEM_ID.PRODUCT_PASS_DETAIL]: {
		id: NAVIGATION_ITEM_ID.PRODUCT_PASS_DETAIL,
		url: PagePaths.PRODUCT_PASS_DETAIL,
		canNavigateToRoles: [Roles.PRODUCT_PASS_MANAGER_ROLE, Roles.PRODUCT_PASS_APPROVER_ROLE],
	},
	[NAVIGATION_ITEM_ID.PRODUCT_PASS_TEMPLATE_DETAIL]: {
		id: NAVIGATION_ITEM_ID.PRODUCT_PASS_TEMPLATE_DETAIL,
		url: PagePaths.PRODUCT_PASS_TEMPLATE_DETAIL,
		canNavigateToRoles: [Roles.ADMIN_ROLE, Roles.PRODUCT_PASS_MANAGER_ROLE],
	},
};

export const NAVIGATION_ITEMS: NavItem[] = [
	{
		...NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT],
		children: [
			NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_ALL_CONTRACTS_BY_PARTNER_ID],
			NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_COMPONENT_READ_PERMISSION],
			NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_COMPONENT_WRITE_PERMISSION],
		],
	},
	{
		...NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT],
		children: [
			NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT_ALL_COMPONENTS_BY_CONTRACT_ID],
			NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT_UPDATE],
		],
	},
	{
		...NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.PART_TYPE_MANAGEMENT],
		children: [
			NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.PART_TYPE_MANAGEMENT_ALL_PART_TYPES],
			NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER],
			NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER_GROUP],
		],
	},
	{
		...NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.PARTNER_MANAGEMENT],
		children: [
			NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.PARTNER_MANAGEMENT_ALL_PARTNERS],
			NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.PARTNER_MANAGEMENT_MANAGE_ASSIGNED_PARTNER_GROUPS],
			NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.PARTNER_MANAGEMENT_PERMITTED_PARTNER_RELATION],
		],
	},
	{
		...NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.PARTNER_GROUP_MANAGEMENT],
		children: [NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.PARTNER_GROUP_MANAGEMENT_ALL_PARTNER_GROUPS]],
	},
	{
		...NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.PRODUCT_PASS],
		children: [
			NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.PRODUCT_PASS_ALL_PRODUCT_PASSES],
			NAVIGATION_ITEM_MAP[NAVIGATION_ITEM_ID.PRODUCT_PASS_ALL_PRODUCT_PASS_TEMPLATES],
		],
	},
];
